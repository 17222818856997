import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    // COLORS FROM LOGO:
    // light-blue is #B3EFFF
    // light orange is #FCA12A
    // dark orange is #FF8A15
    primary: {
      main: '#3CC676',
    },
    secondary: {
      main: '#0AA06E',
    },
  },
  styles: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    global: (props: any) => ({
      nav: {
        marginTop: 'auto',
        marginBottom: '0.5em',
      },
      'nav ul': {
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        justifyContent: 'space-evenly',
        borderTop: '1px solid',
        borderBottom: '1px solid',
        padding: '.25em 0',
      },
      'nav li': {
        width: '150px',
      },
      'nav a': {
        padding: '.8em',
        transition: 'all 500ms',
      },
      'nav a:hover, nav a:focus': {
        backgroundColor: props.colorMode === 'dark' ? 'white' : 'gray.800',
        color: props.colorMode === 'dark' ? 'gray.800' : 'white',
        transition: 'all 500ms',
      },
    }),
  },
});

export default theme;

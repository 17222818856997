import * as React from 'react';
import { Text, VStack, Grid, Heading, Link } from '@chakra-ui/react';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { Logo } from '../Logo';
import { event } from '../lib/analytics';

const Hero = () => {
  const onLinkClickFactory = (sectionName: string) => () => {
    event(`click:${sectionName}`);
  };

  return (
    <Grid minH="100vh" p={3}>
      <ColorModeSwitcher justifySelf="flex-end" />
      <VStack>
        <Logo h="40vmin" pointerEvents="none" />
        <Heading as="h1" size="3xl">
          Sunshine Daydream Casita
        </Heading>
        <Text>Welcome to your San Diego suite!</Text>
      </VStack>
      <nav>
        <ul>
          <li>
            <Link href="#about" onClick={onLinkClickFactory('about')}>
              About
            </Link>
          </li>
          <li>
            <Link href="#location" onClick={onLinkClickFactory('location')}>
              Location
            </Link>
          </li>
          <li>
            <Link href="https://www.airbnb.com/rooms/51124038" target="_blank" rel="noopener noreferrer">
              Book Now
            </Link>
          </li>
        </ul>
      </nav>
    </Grid>
  );
};

export default Hero;

import * as React from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import theme from './theme';
import { event } from '../lib/analytics';
import Hero from './Hero';
import Location from './Location';
import About from './About';
import './App.css';

export const App = () => {
  React.useEffect(() => {
    event('view:home');
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Hero />
        <About />
        <Location />
      </Box>
    </ChakraProvider>
  );
};

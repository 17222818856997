import * as React from 'react';
import {
  Box,
  VStack,
  Grid,
  Heading,
  Text,
  Card,
  CardHeader,
  CardBody,
  Stack,
  StackDivider,
} from '@chakra-ui/react';

const Location = () => (
  <Grid minH="100vh" p={3} id="location" tabIndex={-1} mx="auto" maxW="3xl">
    <VStack spacing={8}>
      <Heading as="h2" size="2xl">
        Location
      </Heading>

      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d6527.75521004958!2d-117.12347185439748!3d32.74885640225275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1688454161433!5m2!1sen!2sus"
        width="600"
        height="450"
        style={{ border: 0 }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />

      <Text>
        The Sunshine Daydream casita is conveniently located in the heart of San
        Diego. With tons of restaurants, shops and bar within walking distance
        and the ocean just a quick drive away, there&apos;s tons to do!
      </Text>

      <Card>
        <CardHeader>
          <Heading size="md">Nearby</Heading>
        </CardHeader>

        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Heading size="xs" textTransform="uppercase">
                North Park
              </Heading>
              <Text pt="2" fontSize="sm">
                .75 miles
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Balboa Park
              </Heading>
              <Text pt="2" fontSize="sm">
                1.5 miles
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                San Diego Zoo
              </Heading>
              <Text pt="2" fontSize="sm">
                2.5 miles
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Downtown
              </Heading>
              <Text pt="2" fontSize="sm">
                3 miles
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                San Diego International Airport
              </Heading>
              <Text pt="2" fontSize="sm">
                5 miles
              </Text>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </VStack>
  </Grid>
);

export default Location;

import * as amplitude from '@amplitude/analytics-browser';
let initialized = false;

export const init = () => {
  if (initialized) {
    return;
  }

  amplitude.init('d892680af93da5ca0c2f9b1e1e7ff416');
  initialized = true;
};

interface EventData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [s: string]: any;
}

export const event = (name: string, data?: EventData) => {
  if (!initialized) {
    return;
  }

  amplitude.track(name, data);
};

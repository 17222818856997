import * as React from 'react';
import {
  UnorderedList,
  ListItem,
  VStack,
  Grid,
  Heading,
  Text,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import casita1 from '../casita1.jpg';

const About = () => (
  <Grid
    minH="100vh"
    p={3}
    id="about"
    tabIndex={-1}
    className="About"
    mx="auto"
    maxW="3xl"
  >
    <VStack spacing={8}>
      <Heading as="h2" size="2xl">
        About
      </Heading>
      <img src={casita1} alt="" />
      <Text>
        The Sunshine Daydream casita is a beautiful guest suite located between
        City Heights and North Park in San Diego, California. This casita has a
        full kitchen with brand new stainless steel appliances. There are
        plates, cups, pots, pans and other kitchen appliances in the cabinets.
        The flat top grill is available outside. To use, remove the grill cover,
        open the propane tank&apos;s handwheel left and then rotate the knobs on
        the grill to turn the gas on. Finally click the red ignite button to
        start the grill.
      </Text>
      <Alert status="warning" variant="left-accent">
        <AlertIcon />
        Quiet hours are from 10pm through 7am.
      </Alert>
      <Heading as="h2" size="lg">
        Checkout Instructions
      </Heading>
      <UnorderedList>
        <ListItem>Checkout is at 10AM</ListItem>
        <ListItem>Clean up any trash and place it in the waste bin</ListItem>
        <ListItem>
          Be sure to grab all of your belongings and any food/drink in the
          refrigerator
        </ListItem>
        <ListItem>Lock the door when you leave</ListItem>
        <ListItem>
          Send a text or message on Airbnb confirming that you have checked out
        </ListItem>
      </UnorderedList>
    </VStack>
  </Grid>
);

export default About;
